import { createContext } from 'react'

interface Ctx {
  isNarrow: boolean
  count: {
    prescription?: number
    consultation?: number
    history?: number
    historyServing?: number
    historyCounts: {
      prescription?: number
      consultation?: number
    }
  }
}

const Context = createContext<Ctx>({
  isNarrow: false,
  count: {
    prescription: 0,
    consultation: 0,
    history: 0,
    historyServing: 0,
    historyCounts: {
      prescription: 0,
      consultation: 0,
    },
  },
})

export default Context
