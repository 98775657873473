import { ComponentProps } from 'react'
import NavLink from './NavLink'
import { signIn } from 'next-auth/react'
import config from '@lib/config'

type Props = ComponentProps<typeof NavLink>
type Link = Props & { footer?: boolean }

const links: Link[] = [
  {
    name: 'consultations',
    href: '/consultations',
    icon: 'Users',
    count: 'consultation',
  },
  {
    name: 'prescriptions',
    href: '/prescriptions',
    icon: 'Archive',
    count: 'prescription',
    roles: config.prescriptionRoles,
  },
  {
    name: 'history',
    href: '/history',
    icon: 'CheckCircle',
    count: 'history',
  },
  {
    name: 'settings',
    icon: 'Settings',
    href: '/settings',
    footer: true,
  },
  {
    name: 'signIn',
    icon: 'LogIn',
    href: '',
    onClick: () => signIn('auth0'),
    auth: 'never',
    footer: true,
  },
]

export default links
