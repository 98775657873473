import { Icon, IconName } from '@drdropin-tech/theseus'
import useIsLoggedIn from '@hooks/useIsLoggedIn'
import { useUser } from '@hooks/useSession'
import { useTranslation } from '@hooks'
import { UserRoles } from '@lib/types/auth'
import { validateRoles } from '@utils/auth'
import { cn } from '@utils/css'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import Context from './Context'

type NavigationLink = {
  name: string
  href: string
  icon: IconName
  roles?: UserRoles[]
  count?: 'prescription' | 'consultation' | 'history'
  auth?: true | 'never'
  className?: string
  onClick?: () => void
}

export default function NavLink(props: NavigationLink) {
  const { route } = useRouter()
  const { translate } = useTranslation()
  const { isNarrow, count } = useContext(Context)
  const user = useUser()
  const loggedIn = useIsLoggedIn()

  if (
    (props.roles && !validateRoles(user, props.roles)) ||
    (props.auth === true && !loggedIn) ||
    (props.auth === 'never' && loggedIn)
  )
    return null

  const isCurrent = route === props.href
  const hasCount = !isNaN(Number(props.count && count[props.count]))
  const counter = props.count && count[props.count]

  return (
    <Link
      href={props.href} onClick={props.onClick}
      className={cn(
        'text-sm font-medium text-moss-green',
        'flex items-center px-4 py-2 rounded-lg',
        isCurrent && 'bg-light-mint',
        isNarrow ? 'gap-4' : 'gap-2',
        props.className,
      )}
      passHref
    >
      <Icon width={20} height={20} name={props.icon} />
      {!isNarrow && translate(`nav.${props.name}`)}

      {hasCount && (
        <div
          className={cn(
            'ml-auto py-1 px-2 rounded-md text-xs font-medium',
            !isCurrent && 'bg-black/10',
          )}
        >
          {counter}
        </div>
      )}
    </Link>
  )
}
